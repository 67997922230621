<template>
  <section class="policy-list">
    <div class="show-table-maxscreen">
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-row-class-name="'table-header'"
        @row-click="rowClick">
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Issue Date</span>
              <v-date-picker
                v-model="issue_date"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handleChange"></v-date-picker>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ showMonth(scope.row.issue_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Effective Date</span>
              <v-date-picker
                v-model="effective_date"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handleChange"></v-date-picker>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{ showMonth(scope.row.effective_date)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="150"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Expiry Date</span>
              <v-date-picker
                v-model="expiryDate"
                type="daterange"
                :rangeSeparator="'-'"
                :placeholder="'Date'"
                @change="handleChange"></v-date-picker>
            </div>
          </template>
          <template slot-scope="scope">
            <span>{{showMonth(scope.row.expiry_date)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Insurer</span>
              <v-select
                v-model="company_id"
                :list="companyList"
                @change="handleChange"></v-select>
            </div>
          </template>
          <template
            slot-scope="scope"
          >
            <div class="flex flex-logo">
              <!--              <span-->
              <!--                v-if="showOtherInsurer(scope.row.company_id)"-->
              <!--                class="company-logo">-->
              <!--                <img-->
              <!--                  :src="getOptionData('insureSVGOptions',scope.row.company_id).logo"-->
              <!--                  alt="company" />-->
              <!--              </span>-->
              <span  class="text-over-flow">{{getOptionName('globalOptions.company',scope.row.company_id)}}</span>

            </div>
          </template>
        </el-table-column>

        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Product</span>
              <v-select
                v-model="product_id"
                :list="productList"
                @change="handleChange"></v-select>
            </div>
          </template>
          <template  slot-scope="scope">
            <span  class="text-over-flow">{{getOptionName('globalOptions.product',scope.row.product_id)}}</span>
          </template>
        </el-table-column>


        <el-table-column
          prop="date"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policyholder</span>
              <v-input
                v-model="policy_holder"
                placeholder="Search"
                @change="handleChange"></v-input>
            </div>
          </template>
          <template  slot-scope="scope">
            <span class="text-over-flow">{{scope.row.policy_holder}}</span>
          </template>
        </el-table-column>

        <el-table-column
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Policy No.</span>
              <v-input
                v-model="policy_no"
                placeholder="Search"
                @change="handleChange"></v-input>
            </div>
          </template>
          <template  slot-scope="scope">
            <span
              class="link hand flex flex-aligin"
              @click="handlerPolicy(scope.row.order_no)">
              <font-awesome-icon icon="link"></font-awesome-icon>

              <span class="text-over-flow">{{scope.row.policy_no||'-'}}</span>
            </span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Gross Premium</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.gross_premium|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Premium Received</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.amount_received|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Referrer Net</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.referrer_commission_vl|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Insurer Net</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.insurer_net_vl|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>RM Comm</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.owner_commission_vl|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>
        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Handling Fee</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span>{{scope.row.handing_charge|formatMoney(2,true)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Status</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <div class="flex">
              <v-button
                :class="getOptionName('buttonColorClassOption',scope.row.policy_status)"
                disabled
                plain>{{getOptionName('statusOptions',scope.row.policy_status)}}</v-button>

              <span
                v-if="scope.row.is_want_renew"
                class="retweet">
                <font-awesome-icon icon="retweet"></font-awesome-icon>
              </span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          width="120"
          :show-overflow-tooltip="true"
        >
          <template slot="header">
            <div class="table-slot-header">
              <span>Referrer</span>
              <span class="block-table"></span>
            </div>
          </template>
          <template  slot-scope="scope">
            <span
              v-if="handlerReferrer(scope.row.referral)"
              class="link flex-aligin flex hand"
              @click="handlerToRefer(scope.row.referral)">
              <font-awesome-icon icon="link"></font-awesome-icon>
              <span class="text-over-flow">{{handlerReferrer(scope.row.referral)}}</span>
            </span>
            <span v-else>NIL</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="show-table-minscreen">
      <div class="search-box ">
        <div
          class="search-title theme-second-blue"
          @click="show = !show">
          <div class="flex flex-center">
            <font-awesome-icon icon="filter"></font-awesome-icon>
            <span class="is-bold">Filter & Sort</span>
          </div>
          <font-awesome-icon :icon="show?'sort-up':'sort-down'"></font-awesome-icon>
        </div>
        <el-collapse-transition>
          <div
            v-show="show"
          >
            <div  class="margintop">
              <el-row>
                <v-row-col>
                  <span class="is-bold">Issue Date</span>
                  <v-date-picker
                    v-model="issue_date"
                    type="daterange"
                    :isPicker="true"
                    :rangeSeparator="'-'"
                    :placeholder="'Date'"
                    @change="handleChange"></v-date-picker>

                </v-row-col>

                <v-row-col>
                  <span class="is-bold">Effective Date</span>
                  <v-date-picker
                    v-model="effective_date"
                    type="daterange"
                    :isPicker="true"
                    :rangeSeparator="'-'"
                    :placeholder="'Date'"
                    @change="handleChange"></v-date-picker>

                </v-row-col>
                <v-row-col >
                  <span class="is-bold">Expiry Date</span>
                  <v-date-picker
                    type="daterange"
                    :isPicker="true"
                    :rangeSeparator="'-'"
                    :placeholder="'Date'"
                    @change="handleChange"
                  ></v-date-picker>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Insurer</span>
                  <v-select
                    v-model="company_id"
                    :list="companyList"
                    @change="handleChange"></v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Product</span>
                  <v-select
                    v-model="product_id"
                    :list="productList"
                    @change="handleChange">
                  </v-select>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policy Holder</span>
                  <v-input
                    v-model="policy_holder"
                    placeholder="Search"
                    @change="handleChange"></v-input>
                </v-row-col>
                <v-row-col>
                  <span class="is-bold">Policy NO.</span>
                  <v-input
                    v-model="policy_no"
                    placeholder="Search"
                    @change="handleChange"></v-input>
                </v-row-col>
              </el-row>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <div
        v-for="(item,index) in tableData"
        :key="index"
        class="table"
        @click="rowClick">


        <div
          class="tr">
          <span>Issue Date</span>
          <span>{{showMonth(item.issue_date)}}</span>
        </div>
        <div
          class="tr">
          <span>Effective Date</span>
          <span>{{showMonth(item.effective_date)}}</span>
        </div>
        <div
          class="tr">
          <span>Expiry Date</span>
          <span>{{showMonth(item.expiry_date)}}</span>
        </div>
        <div
          class="tr">
          <span>Insurer</span>
          <span>{{item.insurer}}</span>
        </div>

        <div
          class="tr">
          <span>Product</span>
          <span>{{getOptionName('globalOptions.company',item.company_id)}}</span>
        </div>

        <div
          class="tr">
          <span>Policyholder</span>
          <span>{{item.policy_holder}}</span>
        </div>
        <div
          class="tr">
          <span>Policy No.</span>
          <span>{{item.policy_no}}</span>
        </div>
        <div
          class="tr">
          <span>Gross Premium</span>
          <span>{{item.gross_premium|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Premium Received</span>
          <span>{{item.amount_received|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Referrer Net</span>
          <span>{{item.referrer_commission_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Insurer Net</span>
          <span>{{item.insurer_net_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>RM Comm</span>
          <span>{{item.owner_commission_vl|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Handling Fee</span>
          <span>{{item.handing_charge|formatMoney(2,true)}}</span>
        </div>
        <div
          class="tr">
          <span>Status</span>
          <v-button
            :class="getOptionName('buttonColorClassOption',item.policy_status)"
            disabled
            plain>{{getOptionName('statusOptions',item.policy_status)}}</v-button>

        </div>
        <div
          class="tr">
          <span>Agency Code</span>
          <span
            v-if="handlerReferrer(item.referral)"
            class="link"
            @click="handlerToRefer(item.referral)">{{handlerReferrer(item.referral)}}</span>
          <span v-else>NIL</span>
        </div>
      </div>
    </div>
    <v-pagination
      :total="pageConfig.total"
      :current="pageConfig.currentPage"
      @handle-current-change="currentChange"
      @handle-size-change="handleSizeChange" />
  </section>
</template>

<script>
import MixinFormat from '@/mixins/format'
import MixinOptions from '@/mixins/options'
import {getTransactionList,getTransactionReportsExport} from '@api/transcation'
import {downloadFile} from '@/utils/download'
export default {
  name: 'transaction',
  mixins: [MixinOptions,MixinFormat],
  props:{

  },
  data(){

    return {
      show:false,
      product_id:'',
      company_id:'',
      policy_holder:'',
      policy_no:'',
      issue_date:'',
      effective_date:'',
      expiryDate:'',
      tableData:[],
      pageConfig: {
        //  分頁配置
        currentPage: 1,
        total: 10,
        size:20
      },
    }
  },
  computed:{
    companyList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.company)
    },
    productList(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.product)
    },
    policy_status_list(){
      let arr = [{name:'All',id:''}]
      return arr.concat(this.globalOptions.policy_status)
    },
    canExport(){
      if(this.issue_date||this.effective_date||this.expiryDate||this.company_id||this.product_id||this.policy_holder||this.policy_no){
        return false
      }
      return true
    }
  },
  watch:{
    canExport:{
      immediate:true,
      handler(val){
        this.$emit('export',val)
      }
    }
  },
  created() {
    this.network().getTransactionList()
  },
  methods:{
    handleExport(){
      this.network().getTransactionExport()
    },
    rowClick(){
      // this.$router.push('/member/referral/statement')
    },
    handlerPolicy(order_no){
      this.$router.push({
        path:'/quotation/premium/info',
        query:{
          order_no
        }
      })
    },
    handlerToRefer(refer){
      let {id } = refer
      this.$router.push('/member/referral/statement/'+id)
    },
    handlerReferrer(data){
      let {name = ''} =data
      return name
    },
    showOtherInsurer(company_id) {
      let arr =  [1,4,11,3]
      return arr.includes(company_id)
    },
    currentChange(page) {
      this.pageConfig.currentPage = page
      this.network().getTransactionList()
    },
    handleSizeChange(size){
      this.pageConfig.size =size
      this.network().getTransactionList()
    },
    handleChange(){
      this.pageConfig.currentPage = 1
      this.network().getTransactionList()
    },
    network() {
      return {
        getTransactionList: async () => {
          const {data} = await getTransactionList({
            page: this.pageConfig.currentPage,
            size: this.pageConfig.size,
            issue_date_start:this.issue_date?this.issue_date[0]: '',
            issue_date_end:this.issue_date?this.issue_date[1]: '',
            effective_date_start: this.effective_date? this.effective_date[0]: '',
            effective_date_end: this.effective_date? this.effective_date[1]: '',
            company_id:this.company_id,
            product_id:this.product_id,
            policy_holder:this.policy_holder,
            policy_no:this.policy_no,
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
          })
          this.tableData = data.data
          let {meta} = data
          this.pageConfig.total = meta.total
        },
        getTransactionExport: async () => {
          let data = await getTransactionReportsExport({
            page: this.pageConfig.currentPage,
            size: this.pageConfig.size,
            issue_date_start:this.issue_date?this.issue_date[0]: '',
            issue_date_end:this.issue_date?this.issue_date[1]: '',
            effective_date_start: this.effective_date? this.effective_date[0]: '',
            effective_date_end: this.effective_date? this.effective_date[1]: '',
            company_id:this.company_id,
            product_id:this.product_id,
            policy_holder:this.policy_holder,
            policy_no:this.policy_no,
            expiry_date_start:this.expiryDate?this.expiryDate[0]: '',
            expiry_date_end:this.expiryDate?this.expiryDate[1]: '',
          })
          downloadFile(data)
        },
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../../../assets/css/policyList';
.flex-logo{
  align-items: center;
  .company-logo{
    width: 30px;
    height: 20px;
    display: flex;
    margin-right: 5px;
    img{
      //width: 100%;
      display: block;
    }
  }
}
</style>
